<template>
    <div class="OwnerSelector">
        <div class="list1">
            <!-- 搜索 -->
            <search-bar v-model="value" @keyup.enter.native="toSearch" :placeholder="$t('mx_mobile.Client.1585115144101')"></search-bar>
        </div>
        <div class="contenClass">
            <li v-for="(item,index) in resultList" :key="index" @click="submit(item)">
                <span>{{item.realName}}</span>
            </li>
        </div>
    </div>

</template>
<script>
import SearchBar from '@/page/Document/Vue/SearchBar/index'
import titleMixin from '@/mixin/title.js'
import MXEventBus from '@/libs/eventBus'
import { mapGetters } from 'vuex'

export default {
    name: 'OwnerSelector',
    // title: '人员选择',
    title: function fc() { return this.$t('mx_mobile.Mail.1585111834931') },
    mixins: [titleMixin],
    props: {

    },
    data() {
        return {
            optionsList: [],
            selectedItem: {},
            resultList: [],
            value: ''
        }
    },
    computed: {
        ...mapGetters(['companie'])
    },
    created() {
        this.getOnwers()
    },
    mounted() {
        // this.setMenu()
    },
    methods: {
        // 停止冒泡
        submit(item) {
            // this.$emit('closePersonnelSelect', this.selectedItem)
            MXEventBus.$emit('selectedOwner', item)
            this.$router.back()
        },
        toSearch(e) {
            const result = this.optionsList.filter(o => o.realName.indexOf(e.target.value) > -1)
            this.resultList = result
        },
        getOnwers() {
            const contactData = {
                dataType: 'contact',
                optCode: 'otView',
                funType: 'withRight',
                moduleCode: 'BF001'
            }

            const defaultList = [{
                realName: this.$t('mx_mobile.workbench.1584941210966'), // 全部
                ctId: ''
            }, {
                realName: this.$t('mx_mobile.workbench.1665633097451'), // 我自己
                ctId: this.companie.ctId
            }
            ]

            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.accountDropList_get, { params: contactData }).then((res) => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    const dataList = res.data.data.filter((data) => data.inUse === 1)
                    this.optionsList = [...defaultList, ...dataList]
                    this.resultList = this.optionsList
                } else {
                    this.optionList = []
                    this.$toast.fail(res.data.msg)
                }
            }, (res) => {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        }
    },
    watch: {
        $route() {
            this.getOnwers()
            this.value = ''
            this.selectedItem = []
        }
    },
    components: {
        'search-bar': SearchBar
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
